import React, { useState, useRef, useEffect, useCallback } from 'react';
import './offplan.css';
import CategoryCard from '../../Components/CategoryCard/CategoryCard';
import Footer from '../../Components/Footer/Footer';
import axios from 'axios';
import { useParams } from 'react-router';
import { APP_CONFIG } from '../../config';
import demo from '../../Assets/estate-hero.jpg';
import fallbackImage from '../../Assets/estate-hero.jpg';

const Offplan = () => {
  let params = useParams();
  let id = params?.id;
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentIndexForImages, setCurrentIndexForImages] = useState(0);
  const [viewImages, setViewImages] = useState(false);
  // const [data, setData] = useState([]);
  const [property, setProperty] = useState([]);
  const [moreFromDeveloper, setMoreFromDeveloper] = useState([]);
  const [isLoadingMoreDeveloper, setIsLoadingMoreDeveloper] = useState(true);
  const carouselRef = useRef(null);
  const carouselRefForImages = useRef(null);

  useEffect(() => {
    const getdet = async () => {
      try {
        const response = await axios.get(
          `${APP_CONFIG.backendUrl}api/offplan/${id}`
        );
        setProperty(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    getdet();
  }, [id]);

  // useEffect(() => {
  //   const getItem = async () => {
  //     try {
  //       const response = await axios.get(
  //         `${APP_CONFIG.backendUrl}api/suboffplan/sub/${id}`
  //       );
  //       setData(response.data);
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   };
  //   getItem();
  // }, [id]);

  useEffect(() => {
    const getMoreFromDeveloper = async () => {
      setIsLoadingMoreDeveloper(true);
      try {
        const response = await axios.get(
          `${APP_CONFIG.backendUrl}api/offplan/${id}/more`
        );
        setMoreFromDeveloper(Array.isArray(response.data) ? response.data : []);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoadingMoreDeveloper(false);
      }
    };
    getMoreFromDeveloper();
  }, [id]);

  const scrollCarousel = useCallback(() => {
    const slideWidth = carouselRef.current.offsetWidth;
    carouselRef.current.scrollLeft = currentIndex * slideWidth;
  }, [currentIndex]);

  const scrollCarouselForImages = useCallback(() => {
    if (viewImages) {
      const slideWidth = carouselRefForImages.current.offsetWidth;
      carouselRefForImages.current.scrollLeft =
        currentIndexForImages * slideWidth;
    }
  }, [currentIndexForImages, viewImages]);

  useEffect(() => {
    scrollCarousel();
    scrollCarouselForImages();
  }, [scrollCarousel, scrollCarouselForImages]);

  const goToNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % property.image.length);
  };

  const goToPrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? property.image.length - 1 : prevIndex - 1
    );
  };

  const goToNextForImages = () => {
    setCurrentIndexForImages(
      (prevIndex) => (prevIndex + 1) % property.image.length
    );
  };

  const goToPrevForImages = () => {
    setCurrentIndexForImages((prevIndex) =>
      prevIndex === 0 ? property.image.length - 1 : prevIndex - 1
    );
  };

  return (
    <>
      {viewImages ? (
        <div className='forbtn_crsl'>
          <button className='prev-button' onClick={goToPrevForImages}>
            <i className='fa-solid fa-chevron-left'></i>
          </button>
          <button className='next-button' onClick={goToNextForImages}>
            <i className='fa-solid fa-chevron-right'></i>
          </button>
          <div className='absolutly' ref={carouselRefForImages}>
            {property.image?.map((image, index) => (
              <img
                key={index}
                src={image.url || demo}
                alt={`Details ${index}`}
                onError={(e) => {
                  e.target.src = fallbackImage;
                }}
              />
            ))}
            <button
              className='exit_viewmore'
              onClick={() => {
                setViewImages(false);
              }}
            >
              <i className='fa-regular fa-circle-xmark'></i>
            </button>
          </div>
        </div>
      ) : null}
      <div className='offplan_wrapper'>
        <div className='off_plan_container'>
          <div className='carousel_images_wrapp'>
            <div className='carousel-con'>
              <div className='carousel_off' ref={carouselRef}>
                {property.image &&
                  property.image.map((image, index) => (
                    <div className='carousel-slide_off' key={index}>
                      <img src={image.url} alt={`Details ${index}`} />
                      <button
                        type='button'
                        className='view-more-btn-offplan'
                        onClick={() => {
                          setViewImages(true);
                        }}
                      >
                        View Images
                      </button>
                    </div>
                  ))}
              </div>
              <button className='prev-button' onClick={goToPrev}>
                <i className='fa-solid fa-chevron-left'></i>
              </button>
              <button className='next-button' onClick={goToNext}>
                <i className='fa-solid fa-chevron-right'></i>
              </button>
            </div>
          </div>
          <div className='description_offplan'>
            <div>
              <h1>{property.name}</h1>
              <div className="offplan_stats">
                <div className="offplan_stats_item">
                  <h6>Property Type:</h6>
                  <span>{property?.appartement_type}</span>
                </div>
                <div className="offplan_stats_item">
                  <h6>Unit Type:</h6>
                  <span>{property?.unit_type}</span>
                </div>
                <div className="offplan_stats_item">
                  <h6>Size:</h6>
                  <span>{property?.size} Sq Ft.</span>
                </div>
                <div className="offplan_stats_item">
                  <h6>Starting Price:</h6>
                  <span>{property?.price}</span>
                </div>
                <div className="offplan_stats_item">
                  <h6>Payment Plan:</h6>
                  <span>{property?.payment_plan}</span>
                </div>
                <div className="offplan_stats_item">
                  <h6>Handover:</h6>
                  <span>{property?.hand_over_date}</span>
                </div>
              </div>
              <h3>{property.category}</h3>
              <p dangerouslySetInnerHTML={{ __html: property.description }}></p>
            </div>
          </div>
          <div className='offplan_features'>
            <div className='feature f_location'>
              <h3>Address</h3>
              <span>{property.address}</span>
              <i className='fa-solid fa-location-crosshairs'></i>
            </div>
            <div className='feature f_availability av'>
              <h3>Expected Availability</h3>
              <span>{property.availability_expected}</span>
              <i className='fa-regular fa-clock'></i>
            </div>

            <div className='feature f_availability loc'>
              <h3>Location</h3>
              <button
                type='button'
                className='buttonag rb_btn locbut'
                onClick={() => {
                  window.location.href = property.location;
                }}
              >
                Check the map
              </button>
              <i className='fa-solid fa-map-location-dot'></i>
            </div>
          </div>

          {/* <div className='offplan_properties'>
            <h1 className='proptitle'>Properties</h1>
            <div
              className='properties_content'
              style={{ transition: 'opacity 1s linear' }}
            >
              {data ? (
                data.map((card) => (
                  <div key={card._id}>
                    <CategoryCard
                      price1={card.price_low}
                      name={card.name}
                      price2={card.price_high}
                      bedroom={card.bedrooms}
                      bathroom={card.bathrooms}
                      size={card.size}
                      id={card._id}
                      type={card.property_type}
                      image={card.image[0]}
                    />
                  </div>
                ))
              ) : (
                <div className='loader-container'>
                  <div className='cube'>
                    <div className='face front'></div>
                    <div className='face back'></div>
                    <div className='face right'></div>
                    <div className='face left'></div>
                    <div className='face top'></div>
                    <div className='face bottom'></div>
                  </div>
                  <p className='first_loading_p'>Loading...</p>
                  <p>Please wait</p>
                </div>
              )}
              <div className='divider_spacer'></div>
            </div>
          </div> */}

          <div className='offplan_properties'>
            <h3 className='proptitle'>More from this developer</h3>
            <div
              className='properties_content'
              style={{ transition: 'opacity 1s linear' }}
            >
              {isLoadingMoreDeveloper ? (
                <div className='loader-container'>
                  <div className='cube'>
                    <div className='face front'></div>
                    <div className='face back'></div>
                    <div className='face right'></div>
                    <div className='face left'></div>
                    <div className='face top'></div>
                    <div className='face bottom'></div>
                  </div>
                  <p className='first_loading_p'>Loading...</p>
                  <p>Please wait</p>
                </div>
              ) : moreFromDeveloper.length > 0 ? (
                moreFromDeveloper.map((card) => (
                  <div key={card._id}>
                    <CategoryCard
                      location={card.address}
                      name={card.name}
                      key={card._id}
                      id={card._id}
                      image={card.image[0]}
                      type={card.property_type}
                    />
                  </div>
                ))
              ) : (
                <p>No more properties from this developer.</p>
              )}
              {/* <div className='divider_spacer'></div> */}
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Offplan;
